.wa-error(@msg:"error") {
    border:3px solid red;
    display: inline-block;
    &:after {
        content: @msg;
        color: red;
    }
}
.wa-warning(@msg:"warn") {
    border:3px solid #ff9100;
    display: inline-block;
    &:after {
        content: @msg;
        color: #ff9100;
    }
}

/* lang 없는 HTML */


/* alt 없는 IMG */
img[alt=" "],
area[alt=" "],
input[type="image"][alt=" "],
img:not([alt]),
area:not([alt]),
input[type="image"]:not([alt]) {
    .wa-error("ATL");
}

/* CAPTION,THEAD 없는 TABLE */
// table:not([role="presentation"]) > :not(caption) {
//     .wa-error("no caption");
// }


/* title 없는 IFRAME */
iframe:not([title]),
iframe[title=""],
iframe[title=" "] {
    .wa-error("no title");
}

/* 새창 알림이 없음 */
a[target="_blank"]:not([title]),
form[target="_blank"][title=""],
form[target="_blank"]:not([title]),{
    .wa-error("새창 알림 없음");
}

/* for 없는 LABEL */
label:not([for]),
label[for=" "],
label[for=""] {
    .wa-error("no for");
}

/* layout 용 테이블에 semantic TAG 있을 떄 */
table[role="presentation"] thead,
table[role="presentation"] tfoot,
table[role="presentation"] caption,
table[role="presentation"] [axis],
table[role="presentation"] [scope],
table[role="presentation"] [headers],
table[role="presentation"] [colgroup] {
    .wa-error("layout table");
}

/* Invalid Nesting */
ul > :not(li),
ol > :not(li),
:not(ul):not(ol) > li,
select > :not(option):not(optgroup),
:not(select):not(optgroup) > option,
optgroup > :not(option),
:not(select) > optgroup,
:not(fieldset) > legend,
tr > :not(td):not(th),
table > *:not(thead):not(tfoot):not(tbody):not(tr):not(colgroup):not(caption) {
    .wa-warning("invalid nesting");
}

/* Invalid DIV position */
b div,
i div,
q div,
em div,
abbr div,
cite div,
code div,
span div,
small div,
label div,
strong div {
    .wa-warning("invalid div");
}

/* javascript: 사용 하는 A */
a[href^="javascript"]:not([role="button"]) {
    .wa-warning("invalid href");
}
